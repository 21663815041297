import React from 'react';
import '../components/layout.css'
import styled from "styled-components";
import RightArrow from "../components/Carousel/RightArrow";
import {Link} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Card = ({item,toggleOpenModal}) => {
  const img = getImage(item.image.localFile)
    return (
      <PicContainer>
        <Image image={img} alt={item.title} />
        <div
          style={{
            width: 180,
            height: 139,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Description>{item.title}</Description>
          <More>
           <Link style={{ marginRight: 10,display:'flex',alignItems:'center' }}>
              <Span onClick={toggleOpenModal}>хочу так же</Span>
            </Link>
            <Arrow onClick={toggleOpenModal}>
              <RightArrow height="18" width="26" color="#4285F4" />
            </Arrow>
          </More>
        </div>
      </PicContainer>
    );
};

export default Card;

const Arrow = styled.div`
  transition-duration: 0.2s;
  cursor:pointer;
  color: white;
  display:flex;
  align-items:center;
  &:hover {
    margin-left: 15px;
    color: #4285f4;
  }
`;

const Span = styled.span`
  transition-duration: .2s;
  color: white;
  &:hover {
    margin-right: 15px;
    color: #4285F4;
    
  }
`;

const Image = styled(GatsbyImage)`
  border-radius: 16px;
  width: 180px;
  height: 180px;
  margin-bottom: 33px;
`

const Description = styled.div`

  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: white;
`

const More = styled(Description)`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  line-height: 46px;
  text-transform: uppercase;
  align-items: center;
  color: white;
`

const PicContainer = styled.div`
  width: 180px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 40px;
`
